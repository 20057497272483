import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { DataContainsNode, useTransformNode } from '../../hooks/useTransformer';
import { MainTitle, TitleWrapper } from './CommonElement';
import Nl2Br from './Nl2Br';
import SharedCss from './SharedCss';

const FaqWrapper = styled.div`
  padding: 50px 30px;

  /** Common Css **/
  ${SharedCss}

  /* PC */
  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    width: 95%;
    max-width: 1024px;
    padding: 160px 0 80px;
    margin: 0 auto;
  }
`;

const FaqTitle = styled(TitleWrapper)`
  body & {
    /* PC */
    @media (min-width: 1024px) {
      width: 25%;
    }
  }
`;

const FaqTitleJa = styled(MainTitle)`
  body & {
    /* PC */
    @media (min-width: 1024px) {
      text-align: left;
    }
  }
`;

const FaqList = styled.ul`
  body & {
    /* PC */
    @media (min-width: 1024px) {
      width: 70%;
    }
  }
`;

const FaqListItem = styled.li`
  body & {
    position: relative;
    padding-top: 20px;
    border-top: 1px solid #000;

    &::before {
      position: absolute;
      top: 32px;
      right: 0;
      display: block;
      width: 20px;
      height: 2px;
      content: '';
      background: #000;
    }

    /* PC */
    @media (min-width: 1024px) {
      padding: 30px 0;

      &::before {
        top: 44px;
        right: 0;
        width: 30px;
        height: 1px;
      }
    }
  }
`;

const FaqListItemQ = styled.h3`
  body & {
    position: relative;
    padding: 0 50px 0 15px;
    font-size: 14px;
    font-weight: 300;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      font-family: 'Mukta Vaani', sans-serif;
      color: #0093bb;
      content: 'Q';
    }

    &::after {
      position: absolute;
      top: 3px;
      right: 9px;
      display: block;
      width: 2px;
      height: 20px;
      content: '';
      background: #000;
      transition: 0.5s;
    }

    &:hover {
      cursor: pointer;
    }

    &.is-active::after {
      transform: rotate(90deg);
    }

    &.is-active::before {
      transform: rotate(0);
      /* opacity: 0; */
    }

    /* PC */
    @media (min-width: 1024px) {
      padding-left: 50px;
      margin: 0;
      font-size: 18px;

      &::after {
        top: 0;
        right: 14px;
        width: 1px;
        height: 30px;
      }
    }
  }
`;

const FaqListItemA = styled.p`
  body & {
    position: relative;
    height: 0;
    padding: 0 15px;
    margin-bottom: 20px;
    overflow: hidden;
    line-height: 0;
    opacity: 0;
    transition-duration: 0.3s;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      font-family: 'Mukta Vaani', sans-serif;
      color: #0093bb;
      content: 'A';
    }

    &.is-open {
      height: auto;
      padding: 0 0 20px 15px;
      line-height: normal;
      opacity: 1;
    }

    /* PC */
    @media (min-width: 1024px) {
      height: 1px;
      padding: 0;
      margin: 0;

      &.is-open {
        height: auto;
        padding: 0 0 20px 50px;
        margin-top: 20px;
        font-size: 18px;
        line-height: normal;
        opacity: 1;
      }
    }
  }
`;

const NEWS_DATA_QUERY = graphql`
  query FaqItemsData {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "projects-faq" } } }
    ) {
      edges {
        node {
          id
          fields {
            language
            country
          }
          frontmatter {
            templateKey
            titleJa
            faqItems {
              question
              answer
            }
          }
        }
      }
    }
  }
`;

type FaqItemProps = {
  item: GatsbyTypes.Maybe<GatsbyTypes.MarkdownRemarkFrontmatterFaqItems>;
};

const FaqItem: React.FC<FaqItemProps> = (props: FaqItemProps) => {
  const [active, setActive] = useState(false);
  const item = props.item;

  return (
    <FaqListItem>
      <FaqListItemQ
        className={active ? 'is-active' : ''}
        onClick={() => setActive(!active)}
      >
        {item?.question}
      </FaqListItemQ>
      <FaqListItemA className={active ? 'is-open' : ''}>
        <Nl2Br text={item?.answer} />
      </FaqListItemA>
    </FaqListItem>
  );
};

const Faq: React.FC = () => {
  const faqData = useStaticQuery<GatsbyTypes.FaqItemsDataQuery>(
    NEWS_DATA_QUERY,
  ) as DataContainsNode;
  const { frontmatter } = useTransformNode(faqData);

  return (
    <FaqWrapper>
      <FaqTitle>
        <FaqTitleJa>{frontmatter?.titleJa}</FaqTitleJa>
      </FaqTitle>
      <FaqList>
        {frontmatter?.faqItems?.map((item, index) => (
          <FaqItem key={'faq-' + index} item={item} />
        ))}
      </FaqList>
    </FaqWrapper>
  );
};

export default Faq;
